//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    title: "",
    articleManual: true,
    dialogChoiceItem: false,
    itemOne: {
      itemId: 0,
      itemCode: "",
      itemReference: "",
      itemBarcode: "",
      description: "",
      costPrice: 0.0,
      qty: 0.0,
      discount1: 0.0,
      toCheck: "S",
      readOnly: "N",
      text: "",
    },
    itemList: [],
    item: undefined,
  }),
  created() {
    //
  },
  mounted() {
    if (sessionStorage["sessDocLineId02"] != 0) {
      this.title = "Edit()";
      this.omplirEdits();
    } else {
      this.title = "New()";
      this.newRecord();
    }
  },
  methods: {
    goBack() {
      this.$router.push("/workorder02list");
    },
    goMenu() {
      this.$router.push("/mainmenu");
    },
    qtyAdd(q) {
      this.itemOne.qty =
        Math.round((parseFloat(this.itemOne.qty) + parseInt(q)) * 100) / 100;
    },
    setFocus(nomCamp) {
      setTimeout(() => {
        if (document.getElementById(nomCamp) != null) {
          document.getElementById(nomCamp).focus();
        }
      }, 200);
    },
    modeReadOnly() {
      return this.itemOne.readOnly == "Y";
    },
    modeReadOnly_Item() {
      return this.itemOne.readOnly == "Y" || !this.articleManual;
    },
    newRecord() {
      this.articleManual = true;
      this.itemOne.docLineId = 0;
      this.itemOne.itemId = 0;
      this.itemOne.itemCode = "";
      this.itemOne.itemReference = "";
      this.itemOne.itemBarcode = "";
      this.itemOne.description = "";
      this.itemOne.costPrice = 0.0;
      this.itemOne.qty = 1.0;
      this.itemOne.discount1 = 0.0;
      this.itemOne.text = "";
      this.itemOne.toCheck = "S";
      this.itemOne.readOnly = "N";
      sessionStorage["sessDocLineId02"] = 0;
      this.setFocus("TextField_ItemCode");
    },
    omplirEdits() {
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio02_getitemsbyid", {
          token: sessionStorage.getItem("Token"),
          docCompanyId: parseInt(sessionStorage["sessDocCompanyId"]),
          docYear: parseInt(sessionStorage["sessDocYear"]),
          docSerialId: parseInt(sessionStorage["sessDocSerialId"]),
          docId: parseInt(sessionStorage["sessDocId"]),
          docLineId: parseInt(sessionStorage["sessDocLineId"]),
          docLineId02: parseInt(sessionStorage["sessDocLineId02"]),
        })
        .then((response) => {
          this.itemOne = response.data[0];
          this.articleManual = this.itemOne.itemId == 0;
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    appendUpdateRecord(bGoback) {
      this.dialog = false;
      if (parseInt(sessionStorage["sessDocLineId02"]) == 0) {
        axios
          .post(api.URL() + "/api/v1/gordresfabricacio02_append", {
            token: sessionStorage.getItem("Token"),
            docCompanyId: parseInt(sessionStorage["sessDocCompanyId"]),
            docYear: parseInt(sessionStorage["sessDocYear"]),
            docSerialId: parseInt(sessionStorage["sessDocSerialId"]),
            docId: parseInt(sessionStorage["sessDocId"]),
            docLineId: parseInt(sessionStorage["sessDocLineId"]),
            itemId: this.itemOne.itemId,
            itemCode: this.itemOne.itemCode,
            itemReference: this.itemOne.itemReference,
            itemBarcode: this.itemOne.itemBarcode,
            description: this.itemOne.description,
            costPrice: parseFloat(this.itemOne.costPrice),
            qty: parseFloat(this.itemOne.qty),
            discount1: parseFloat(this.itemOne.discount1),
            toCheck: this.itemOne.toCheck,
            text: this.itemOne.text,
          })
          .then(() => {
            if (bGoback) {
              this.goBack();
            } else {
              this.newRecord();
            }
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      } else {
        axios
          .post(api.URL() + "/api/v1/gordresfabricacio02_update", {
            token: sessionStorage.getItem("Token"),
            docCompanyId: parseInt(sessionStorage["sessDocCompanyId"]),
            docYear: parseInt(sessionStorage["sessDocYear"]),
            docSerialId: parseInt(sessionStorage["sessDocSerialId"]),
            docId: parseInt(sessionStorage["sessDocId"]),
            docLineId: parseInt(sessionStorage["sessDocLineId"]),
            docLineId02: parseInt(sessionStorage["sessDocLineId02"]),
            itemId: this.itemOne.itemId,
            itemCode: this.itemOne.itemCode,
            itemReference: this.itemOne.itemReference,
            itemBarcode: this.itemOne.itemBarcode,
            description: this.itemOne.description,
            costPrice: parseFloat(this.itemOne.costPrice),
            qty: parseFloat(this.itemOne.qty),
            discount1: parseFloat(this.itemOne.discount1),
            toCheck: this.itemOne.toCheck,
            text: this.itemOne.text,
          })
          .then(() => {
            if (bGoback) {
              this.goBack();
            } else {
              this.newRecord();
            }
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    deleteRecord() {
      if (parseInt(sessionStorage["sessDocLineId02"]) != 0) {
        axios
          .post(api.URL() + "/api/v1/gordresfabricacio02_delete", {
            token: sessionStorage.getItem("Token"),
            docCompanyId: parseInt(sessionStorage["sessDocCompanyId"]),
            docYear: parseInt(sessionStorage["sessDocYear"]),
            docSerialId: parseInt(sessionStorage["sessDocSerialId"]),
            docId: parseInt(sessionStorage["sessDocId"]),
            docLineId: parseInt(sessionStorage["sessDocLineId"]),
            docLineId02: parseInt(sessionStorage["sessDocLineId02"]),
          })
          .then(() => {
            this.goBack();
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      } else {
        this.goBack();
      }
    },
    findItem(strValue) {
      if (strValue.trim != "") {
        axios
          .post(api.URL() + "/api/v1/garticles_find", {
            token: sessionStorage.getItem("Token"),
            text: strValue,
          })
          .then((response) => {
            this.itemList = response.data;

            if (this.itemList == null) {
              this.$alert.show({
                message: "Item not found",
              });
            }
            if (this.itemList != null && this.itemList.length == 1) {
              this.articleGetById(this.itemList[0].itemId);
            }
            if (this.itemList != null && this.itemList.length > 1) {
              this.dialogChoiceItem = true;
            }
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      } else {
        this.$alert.show({
          message: "Empty value",
        });
      }
    },
    articleGetById(itemId) {
      if (itemId != null && itemId.trim != "") {
        axios
          .post(api.URL() + "/api/v1/garticles_getbyid", {
            token: sessionStorage.getItem("Token"),
            itemId: parseInt(itemId),
          })
          .then((response) => {
            this.articleManual = false;
            this.item = response.data;
            this.itemOne.itemId = this.item.itemId;
            this.itemOne.itemCode = this.item.itemCode;
            this.itemOne.description = this.item.itemName;
            this.itemOne.costPrice = this.item.costPrice;
            this.itemOne.discount1 = 0; //TODO Tarifes de client
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };

      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
